<template>
    <div class='index'>
        <div class="logo_b logo_b1">
            <img src="../../../assets/img/xf1.png" alt="">
        </div>
        <div class="logo_b logo_b2">
            <img src="../../../assets/img/xf2.png" alt="">
        </div>
        <div class="conven_box" @click="goconven">正汉互联网医院平台自律公约</div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    watch: {},
    computed: {},
    created() { },
    mounted() { },
    methods: {
        goconven(){
            this.$router.push('/Convention')
        }
    },
};
</script>
<style lang="scss" scoped>
.index {
    position: fixed;
    bottom: 10px;
    right: 0;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .logo_b {
        width: 119px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-bottom: 10px;
        // box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .1);

        img {
            width: 100%;
            display: block;
        }
    }

    .conven_box {
        width: 113px;
        font-size: 16px;
        background: #fff;
        padding: 5px 10px;
        border-radius: 1px;
        box-shadow: 0 0 16px -12px #000;
        cursor: pointer;
        &:hover{
            color:#2B3D9D ;
        }
    }

    .logo_b1 {
        // background-color: #fff !important;
    }

    .logo_b2 {
        // background-color: #fff !important;
    }
}

.ewmimgs {
    width: 100%;

    img {
        width: 100%;
        display: block;
    }
}
</style>