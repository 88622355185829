<template>
  <div class="index">
    <div class="about bgeee">
      <div class="same_width">
        <div class="bgfff">
          <div class="banner_height"><img src="../../assets/img/lou.png"></div>
          <div class="about_main">
            <h2>无锡正汉互联网医院介绍</h2>
            <p> 无锡正汉互联网医院是以互联网+医疗健康为核心的互联网医疗服务商，是国家发布《关于促进“互联网+医疗健康”发展的意见》以来，较早创立、具有合法资质的互联网医院。 </p>
            <p>
              坚持贯彻、积极响应国家号召，努力解决中国医疗资源不平衡和人们日益增加的健康医疗需求之间的矛盾，正汉互联网医院致力打造一站式医疗健康生态系统，秉承着“专业、规范、创新、至善”的理念，为广大患者提供便捷、完善的互联网+医疗健康服务。
              凭借“互联网+医疗健康”信息服务体系的支撑，正汉互联网医院扎实推进医疗资源整合，与专家建立协作关系，精准对接、满足群众多层次多样化的健康需求，拓展七大健康医疗服务模式，打造五大互联网医疗服务特色，通过智慧系统应用，引领社会大众轻松拥抱健康生活。
            </p>
            <h2>正汉互联网医院三大目标</h2>
            <p> 助力政府：服务社会、分级诊疗 </p>
            <p> 协助医生：实现价值、升级专业 </p>
            <p> 造福百姓：方便就医、降低花费 </p>
            <h2>正汉互联网医院院训</h2>
            <p> 专业 规范 创新 至善</p>
            <h2>六大服务功能</h2>
            <p> 整合资源、通力协作，优化患者求医问药的“最后一公里”。</p>
            <div class="ju_b">1 智能分诊：
              <div class="ju">针对患者病情描述和历史记录产生智能结果，向患者提出分诊建议。</div>
              2 在线咨询：
              <div class="ju">挂号咨询、健康指导、慢病管理、常见问题解答等业务功能，方便患者极简线上就医。</div>
              3 预约挂号：
              <div class="ju">患者根据自己时间在线预约注册医生，不受线下传统医院空间时间限制。</div>
              4 在线诊疗：
              <div class="ju">针对常见病、慢性病患者，开设线上云诊室，实现患者的线上就诊服务。</div>
              5 在线购药：
              <div class="ju">患者离开医院后，可选择接受自己主治医生长期管理，在线复诊并送药到家。</div>
              6 慢病管理：
              <div class="ju">通过健康档案和疾病筛查，实现慢病的预防、诊断、治疗、护理、教育、管理、随访等服务。</div>
            </div>
            <h2>五大服务特色</h2>
            <p> 促进优质医疗资源效率升级，让群众享受优质互联网医疗服务。</p>
            <div class="ju_b">1、专业资质背书，服务更规范：
              <div class="ju">具备互联网医院医疗机构资质，形成互联网医疗规范体系，聘请资深专家，确保远程诊疗专业且规范。</div>
              2、七重服务模式，匹配更精准：
              <div class="ju">应对患者需求推出七重医疗服务，并在核心诊疗环节开发专家一对一、一对多科室、大会场、远程中心等多种诊疗场景。</div>
              3、医疗数据可视，专家更高效：
              <div class="ju">健康医疗大数据可视化结果展示，使大专家不再受到时间空间的限制，响应更快、效率更高,医疗服务能力大幅升级。</div>
              4、信息高速互联，患者少跑腿：
              <div class="ju"> 通过在线预约，与医生沟通面诊，在线支付，药包快递到家，充分发挥互联优势，让信息多跑路，患者少跑腿。</div>
              5、专业运营团队，医生更省心：
              <div class="ju">拥有涵盖医疗、药品、服务、信息等多领域的专业运营团队，在诊疗核心领域配备“正医健康助理”，打造优质互联网医疗体验。</div>
              <div>（苏）医广【2024】第11-12-3202-397号</div>
            </div>
            <!-- <h2>发展历程</h2>
            <div class="step_main">
              <div class="dflex1"><span>2021<em class="nian">年</em></span>
                <div class="flex1">
                  <p> 构建以患者为中心的全病程服务，打造链接患者、医生、医院、药企和保险的IT、数据体系、建设数字化基础设施 </p>
                </div>
              </div>
              <div class="dflex1"><span>2020<em class="nian">年</em></span>
                <div class="flex1">
                  <p>圆心公益基金会、圆心研究院成立，全方位赋能医疗行业</p>
                </div>
              </div>
              <div class="dflex1"><span>2019<em class="nian">年</em></span>
                <div class="flex1">
                  <p>圆心惠保成立，“药安心”特药险上线，创下3天307万份保单订阅</p>
                  <p>无界进修成立，承办国家卫健委“中国现代医院管理智库”</p>
                </div>
              </div>
              <div class="dflex1"><span>2018<em class="nian">年</em></span>
                <div class="flex1">
                  <p>圆心医疗科技成立，为医院建设运营互联网医院及处方共享平台</p>
                </div>
              </div>
              <div class="dflex1"><span>2017<em class="nian">年</em></span>
                <div class="flex1">
                  <p> 妙手医生获得互联网医院牌照，打造服务医患的互联网医疗创新平台 </p>
                </div>
              </div>
              <div class="dflex1"><span>2016<em class="nian">年</em></span>
                <div class="flex1">
                  <p>圆心药房开启院边DTP专业药房新模式</p>
                </div>
              </div>
              <div class="dflex1"><span>2015<em class="nian">年</em></span>
                <div class="flex1">
                  <p>圆心科技成立</p>
                  <p>妙手医生APP上线，开启互联网医患连接新方式</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from "../../components/pc/home/header.vue";
  import Footer from "../../components/pc/home/footer.vue";
  import seo from "../../common/seo"
  export default {
    metaInfo: {
      ...seo.about
    },
    components: {
      Header,
      Footer
    },
    data() {
      return {

      }
    },
    mounted() {
      this.$root.Headerbgc = '#fff'
      // document.dispatchEvent(new Event('render-event'))
    }

  }
</script>

<style lang="scss" scoped>
  .index {
    .bgeee {
      background: #eee;
      padding-top: 120px;

      .same_width {
        width: 1200px;
        margin: 0 auto;
        display: flex;

        .bgfff {
          background: #fff;
          text-align: left;

          .banner_height {
            width: 1200px;
            // height: 368px;
            line-height: 368px;
            text-align: center;
            margin: 0 auto;

            img {
              vertical-align: middle;
              width: auto;
              max-width: 100%;
              display: block;
            }
          }

          .about_main {
            padding: 0 100px 100px 100px;
            color: #333;

            .ju_b {
              font-size: 14px;
            }

            .ju {
              text-indent: 24px;
            }

            h2 {
              font-size: 32px;
              font-weight: 400;
              padding: 60px 0 40px 0;
              color: #000;
            }

            p {
              font-size: 14px;
              line-height: 28px;
              margin-bottom: 20px;
              text-align: left;
              position: relative;
            }

            .step_main {
              margin-left: 100px;
            }

            .dflex1 {
              display: flex;
              font-size: 14px;

              span {
                font-size: 24px;
                font-weight: 500;
                color: #000;
                padding-right: 48px;
                position: relative;

                .nian {
                  font-size: 14px;
                  font-style: normal;
                }
              }

              .flex1 {
                flex: 1;
                border-left: 1px solid #ddd;
                padding: 0 0 60px 60px;
                text-align: left;
                position: relative;

                p:before {
                  content: "";
                  width: 12px;
                  height: 12px;
                  background: #00be8c;
                  border-radius: 100%;
                  position: absolute;
                  top: 7px;
                  left: -66px;
                  z-index: 100;
                }
              }

              .dflex1:last-child .flex1:after {
                content: "";
                height: 70px;
                background: #fff;
                width: 20px;
                position: absolute;
                left: -5px;
                bottom: 0;
              }


              p {
                margin-bottom: 0;
              }
            }


            .dflex1:first-child .flex1:after {
              content: "";
              height: 18px;
              background: #fff;
              width: 20px;
              position: absolute;
              left: -5px;
              top: 0;
            }

            .dflex1:last-child .flex1:after {
              content: "";
              height: 70px;
              background: #fff;
              width: 20px;
              position: absolute;
              left: -5px;
              bottom: 0;
            }
          }
        }
      }
    }
  }
</style>