import {
  createRouter,
  createWebHistory
} from 'vue-router'
import store from "../store/index";
import Home from '../views/pc/Home.vue'
import About from '../views/pc/About.vue'
import Join from '../views/pc/Join.vue'
import Question from '../views/pc/Question.vue'
import News from '../views/pc/News.vue'
import tipArticle from '../views/pc/tipArticle.vue'
import Con from '../views/pc/NewsContent.vue'
import Team from '../views/pc/Team.vue'
import Medicalservices from '../views/pc/Medicalservices.vue'
import visitguide from '../views/pc/Visitguide.vue'
import feedback from '../views/pc/feedback.vue'
import Loading from '../views/loading.vue'
import Convention from '../views/convention.vue'
import mHome from '../views/Mobile/home.vue'
import mAbout from '../views/Mobile/about.vue'
import nAbout from '../views/Mobile/about.vue'
import mQuestion from '../views/Mobile/question.vue'
import mJoin from '../views/Mobile/join.vue'
import mMedicalservices from '../views/Mobile/medicalservices.vue'
import mVisitguide from '../views/Mobile/visitguide.vue'
import mTeam from '../views/Mobile/team.vue'
import mKf from '../views/Mobile/kf.vue'
import mNews from '../views/Mobile/news.vue'
import mNewContent from '../views/Mobile/newcontent.vue'
import mFeedback from '../views/Mobile/feedback.vue'

const routes = [

  // pc
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      KeepAlive: true
    }
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/join',
    name: 'Join',
    component: Join
  }, {
    path: '/question',
    name: 'Question',
    component: Question
  }, {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/tipArticle',
    name: 'tipArticle',
    component: tipArticle
  },

  {
    path: '/newsContent',
    name: 'Content',
    component: Con
  },

  {
    path: '/team',
    name: 'Team',
    component: Team
  },
  {
    path: '/medicalservices',
    name: 'Medicalservices',
    component: Medicalservices
  },
  {
    path: '/visitguide',
    name: 'visitguide',
    component: visitguide
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: feedback
  },


  // Loading
  {
    path: '/Loading',
    name: 'Loading',
    meta: {
      h: 0,
      f: 0
    },
    component: Loading
  },

  {
    path: '/Convention',
    name: 'Convention',
    meta: {
      h: 0,
      f: 0
    },
    component: Convention
  },




  // Mobile
  {
    path: '/mHome',
    name: 'mHome',
    component: mHome
  },
  {
    path: '/mAbout',
    name: 'mAbout',
    component: mAbout
  },
  {
    path: '/nAbout',
    name: 'nAbout',
    component: nAbout
  },

  {
    path: '/mQuestion',
    name: 'mQuestion',
    component: mQuestion
  }, {
    path: '/mJoin',
    name: 'mJoin',
    component: mJoin
  }, {
    path: '/mMedicalservices',
    name: 'mMedicalservices',
    component: mMedicalservices
  }, {
    path: '/mVisitguide',
    name: 'mVisitguide',
    component: mVisitguide
  },
  {
    path: '/mTeam',
    name: 'mTeam',
    component: mTeam
  },
  {
    path: '/mNews',
    name: 'mNews',
    component: mNews
  }, {
    path: '/mNewContent',
    name: 'mNewContent',
    component: mNewContent,
    meta: {
      h: 0,
      f: 1
    },
  },
  {
    path: '/mFeedback',
    name: 'mFeedback',
    component: mFeedback,
  },


  {
    path: '/mKf',
    name: 'mKf',
    meta: {
      h: 0,
      f: 1
    },
    component: mKf
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function (to, from, next) {
  let Jump = store.state.Jump
  let JumpPath = store.state.JumpPath
  // console.log('需要跳转',Jump);
  // console.log('跳转路由',JumpPath);
  if (Jump) {
    store.commit('UPDATEJump', 0)
    router.replace(JumpPath)
    return
  }

  // if (to.fullPath != '/Loading') {
  //   router.replace('/Loading')
  //   return;
  // }
  next();
})

export default router